// 1
const DermisUnitAccount = [
    {
        value: '/平方英尺',
        label: '/平方英尺'
    }, {
        value: '/平方尺',
        label: '/平方尺'
    }
]
const DermisUnitParameter = [
    {
        label: "工艺",
        prop: "attributeValue7",
        isSelectOption: true,
    }, {
        label: "材质",
        prop: "attributeValue2",
        isSelectOption: true,
    }, {
        label: "厚度",
        prop1: "attributeValue3",
        placeholderText1: '请输入最小厚度',
        prop2: "attributeValue14",
        placeholderText2: '请输入最大厚度',
        isDecimalInput: true,
        DecimalMaxLength: 8,
        maxValue: '99999.99'
    }, {
        label: "硬度",
        prop1: "attributeValue5",
        placeholderText1: '请输入最小硬度',
        prop2: "attributeValue15",
        placeholderText2: '请输入最大硬度',
        isDecimalInput: true,
        DecimalMaxLength: 8,
        maxValue: '99999.99'
    }, {
        label: "接受定制花纹",
        prop: "attributeValue9",
        isCustomMade: true,//是否定制类型
        monopolizeLine: true, // 独占一行
    }, {
        label: "接受定制厚度",
        prop: "attributeValue10",
        isCustomMade: true,//是否定制类型
        monopolizeLine: true, // 独占一行
    }, {
        label: "接受定制工艺",
        prop: "attributeValue11",
        isCustomMade: true,//是否定制类型
        monopolizeLine: true, // 独占一行
    }, {
        label: "平均张幅",
        prop1: "attributeValue12",
        placeholderText1: '请输入最小平均张幅',
        prop2: "attributeValue16",
        placeholderText2: '请输入最大平均张幅',
        isDecimalInput: true,
        DecimalMaxLength: 13,
        maxValue: '9999999999.99'
    }, {
        label: "张幅单位",
        prop: "attributeValue13",
    }, {
        label: "等级",
        prop: "attributeValue8",
    }
]
const DermisUnitproductAttribute = {
    attributeValue1: "/平方英尺",//计价单位单位
    attributeValue7: [],//工艺
    attributeValue2: "",//材质
    attributeValue3: "",//最小厚度
    attributeValue14: "",//最大厚度
    attributeValue4: "mm",//厚度单位
    attributeValue5: "",//硬度最小
    attributeValue15: "",//硬度最大
    attributeValue6: "N/mm²",//硬度单位
    attributeValue9: "Y",//接受定制花纹
    attributeValue10: "Y",//接受定制厚度
    attributeValue11: "Y",// 接受定制工艺
    attributeValue12: "",//平均张幅（最小）
    attributeValue16: "",//平均张幅（最大）
    attributeValue13: "平方英尺/张",//张幅单位
    attributeValue8: "未设置",//等级
}

// 2
const ArtificialLeatherUnitAccount = [
    {
        value: '/码',
        label: '/码'
    }, {
        value: '/米',
        label: '/米'
    }, {
        value: '/平方英尺',
        label: '/平方英尺'
    }, {
        value: '/平方尺',
        label: '/平方尺'
    }
]
const ArtificialLeatherParameter = [
    {
        label: "底基类型",
        prop: "attributeValue2",
    }, {
        label: "工艺类型",
        prop: "attributeValue3",
    }, {
        label: "风格类型",
        prop: "attributeValue4",
    }, {
        label: "产品功能",
        prop: "attributeValue5",
    }, {
        label: "厚度",
        prop: "attributeValue6",
        placeholderValue: '请输入厚度',
    }, {
        label: "张幅",
        prop1: "attributeValue8",
        placeholderText1: '请输入最小张幅',
        prop2: "attributeValue9",
        placeholderText2: '请输入最大张幅',
        isDecimalInput: true,
        DecimalMaxLength: 8,
        maxValue: '99999.99'
    }, {
        label: "张幅单位",
        prop: "attributeValue10",
    },
]
const ArtificialLeatherproductAttribute = {
    attributeValue1: "/码",//计价单位单位
    attributeValue2: "",
    attributeValue3: "",//
    attributeValue4: "",//
    attributeValue5: "",//
    attributeValue6: "",//
    attributeValue7: "mm",//
    attributeValue8: "",//
    attributeValue9: "",//
    attributeValue10: "/码",//
    attributeValue11: "",// 
    attributeValue12: "",//
    attributeValue13: "",//
    attributeValue14: "",//
    attributeValue15: "",//
    attributeValue16: "",//
}
// 3
const HardwareUnitAccount = [
    {
        value: '/个',
        label: '/个'
    }, {
        value: '/条',
        label: '/条'
    }
]
const HardwareParameter = [
    {
        label: "材质",
        prop: "attributeValue2",
    }, {
        label: "工艺",
        prop: "attributeValue3",
    },
    {
        label: "尺寸(长)",
        prop: "attributeValue4",
        placeholderValue: '请输入尺寸(长)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    },
    {
        label: "尺寸(宽)",
        prop: "attributeValue5",
        placeholderValue: '请输入尺寸(宽)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "尺寸(高)",
        prop: "attributeValue6",
        placeholderValue: '请输入尺寸(高)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "内径",
        prop: "attributeValue12",
        placeholderValue: '请输入内径',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    },
    {
        label: "尺寸单位",
        prop: "attributeValue7",
    }, {
        label: "线粗",
        prop: "attributeValue8",
        placeholderValue: '请输入线粗',
    }, {
        label: "重量",
        prop: "attributeValue10",
        placeholderValue: '请输入重量',
    },
]
const HardwareproductAttribute = {
    attributeValue1: "/个",//计价单位单位
    attributeValue2: "未设置",
    attributeValue3: "",//
    attributeValue4: "",//
    attributeValue5: "",//
    attributeValue6: "",//
    attributeValue7: "mm",//
    attributeValue8: "",//
    attributeValue9: "mm",//
    attributeValue10: "",//
    attributeValue11: "g",// 
    attributeValue12: "",//
    attributeValue13: "",//
    attributeValue14: "",//
    attributeValue15: "",//
    attributeValue16: "",//
}
// 4
const HeelSoleUnitAccount = [
    {
        value: '/双',
        label: '/双'
    }, {
        value: '/只',
        label: '/只'
    }
]
const HeelSoleUnitParameter = [
    {
        label: "类型",
        prop: "attributeValue2",
    }, {
        label: "材质",
        prop: "attributeValue3",
    },
    {
        label: "工艺",
        prop: "attributeValue4",
    },
    {
        label: "尺码范围",
        prop1: "attributeValue5",
        placeholderText1: '请输入最小尺码',
        prop2: "attributeValue6",
        placeholderText2: '请输入最大尺码',
        isRadiusInput: true,
        DecimalMaxLength: 8,
        maxValue: '99999.99'

    },
    {
        label: "尺码单位",
        prop: "attributeValue7",
    },
    {
        label: "风格",
        prop: "attributeValue8",
    },
    {
        label: "功能",
        prop: "attributeValue9",
    },
    {
        label: "前掌(宽)",
        prop: "attributeValue10",
        placeholderValue: '请输入前掌(宽)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "前掌(高)",
        prop: "attributeValue11",
        placeholderValue: '请输入前掌(高)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "后跟(宽)",
        prop: "attributeValue12",
        placeholderValue: '请输入后跟(宽)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "后跟(高)",
        prop: "attributeValue13",
        placeholderValue: '请输入后跟(高)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "腰位(宽)",
        prop: "attributeValue14",
        placeholderValue: '请输入腰位(宽)',
    }
]
const HeelSoleUnitproductAttribute = {
    attributeValue1: "/双",//计价单位单位
    attributeValue2: "未设置",
    attributeValue3: "未设置",//
    attributeValue4: "未设置",//
    attributeValue5: "",//
    attributeValue6: "",//
    attributeValue7: "国际码",//
    attributeValue8: "未设置",//
    attributeValue9: "未设置",//
    attributeValue10: "",//
    attributeValue11: "",// 
    attributeValue12: "",//
    attributeValue13: "",//
    attributeValue14: "",//
    attributeValue15: "mm",//
    attributeValue16: "",//
}
// 5
const ClothUnitAccount = [
    {
        value: '/码',
        label: '/码'
    }, {
        value: '/米',
        label: '/米'
    }, {
        value: '/尺',
        label: '/尺'
    }
]
const ClothUnitParameter = [
    {
        label: "材质类型",
        prop: "attributeValue2",
    }, {
        label: "厚度",
        prop: "attributeValue3",
        placeholderValue: '请输入厚度',
    }, {
        label: "宽度",
        prop: "attributeValue5",
        placeholderValue: '请输入宽度',
    }, {
        label: "最大长度",
        prop: "attributeValue7",
        placeholderValue: '请输入最大长度',
    },
]
const ClothUnitLeatherproductAttribute = {
    attributeValue1: "/双",//计价单位单位
    attributeValue2: "",
    attributeValue3: "",//
    attributeValue4: "mm",//
    attributeValue5: "",//
    attributeValue6: "码",//
    attributeValue7: "",//
    attributeValue8: "码",//
    attributeValue9: "",//
    attributeValue10: "",//
    attributeValue11: "",// 
    attributeValue12: "",//
    attributeValue13: "",//
    attributeValue14: "",//
    attributeValue15: "",//
    attributeValue16: "",//
}
// 6
const BraidedCordUnitAccount = [
    {
        value: '/码',
        label: '/码'
    }, {
        value: '/米',
        label: '/米'
    }, {
        value: '/尺',
        label: '/尺'
    }
]
const BraidedCordParameter = [
    {
        label: "材质类型",
        prop: "attributeValue2",
    }, {
        label: "编织类型",
        prop: "attributeValue3",
    }, {
        label: "厚度",
        prop: "attributeValue4",
        placeholderValue: '请输入厚度',
    }, {
        label: "宽度",
        prop: "attributeValue6",
        placeholderValue: '请输入宽度',
    }, {
        label: "最大长度",
        prop: "attributeValue8",
        placeholderValue: '请输入最大长度',
    }
]
const BraidedCordLeatherproductAttribute = {
    attributeValue1: "/码",//计价单位单位
    attributeValue2: "",
    attributeValue3: "",//
    attributeValue4: "",//
    attributeValue5: "mm",//
    attributeValue6: "",//
    attributeValue7: "码",//
    attributeValue8: "",//
    attributeValue9: "码",//
    attributeValue10: "",//
    attributeValue11: "",// 
    attributeValue12: "",//
    attributeValue13: "",//
    attributeValue14: "",//
    attributeValue15: "",//
    attributeValue16: "",//
}
// 7
const ShoesUnitAccount = [
    {
        value: '/双',
        label: '/双'
    }, {
        value: '/只',
        label: '/只'
    }
]
const ShoesParameter = [
    {
        label: "面料",
        prop: "attributeValue2",
        placeholderValue: '请输入面料',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "适用人群",
        prop: "attributeValue3",
        placeholderValue: '请输入适用人群',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "风格",
        prop: "attributeValue4",
    }, {
        label: "功能",
        prop: "attributeValue5",
    }, {
        label: "尺码范围",
        prop1: "attributeValue6",
        placeholderText1: '请输入最小尺码',
        prop2: "attributeValue7",
        placeholderText2: '请输入最大尺码',
        isRadiusInput: true,
        DecimalMaxLength: 8,
        maxValue: '99999.99'
    }, {
        label: "尺码单位",
        prop: "attributeValue8",
    },

]

const ShoesproductAttribute = {
    attributeValue1: "/双",//计价单位单位
    attributeValue2: "",
    attributeValue3: "",//
    attributeValue4: "未设置",//
    attributeValue5: "未设置",//
    attributeValue6: "",//
    attributeValue7: "",//
    attributeValue8: "国际码",//
    attributeValue9: "",//
    attributeValue10: "",//
    attributeValue11: "",// 
    attributeValue12: "",//
    attributeValue13: "",//
    attributeValue14: "",//
    attributeValue15: "",//
    attributeValue16: "",//
}

// 8
const BagsSuitcasesUnitAccount = [
    {
        value: '/个',
        label: '/个'
    }
]
const BagsSuitcasesParameter = [
    {
        label: "款式",
        prop: "attributeValue2",
    }, {
        label: "面料",
        prop: "attributeValue3",
        placeholderValue: '请输入面料',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "里料",
        prop: "attributeValue4",
        placeholderValue: '请输入里料',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "规格(长)",
        prop: "attributeValue5",
        placeholderValue: '请输入规格(长)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "规格(宽)",
        prop: "attributeValue6",
        placeholderValue: '请输入规格(宽)',
        MaxLength: '30',
        inputType: 'text',
        TextAreaisLimit: false
    }, {
        label: "规格(高)",
        prop: "attributeValue7",
        placeholderValue: '请输入规格(高)',
    }, {
        label: "容量",
        prop: "attributeValue9",
        placeholderValue: '请输入容量',
    },
]
const BagsSuitcasesproductAttribute = {
    attributeValue1: "/个",//计价单位单位
    attributeValue2: "未设置",
    attributeValue3: "",//
    attributeValue4: "",//
    attributeValue5: "",//
    attributeValue6: "",//
    attributeValue7: "",//
    attributeValue8: "cm",//
    attributeValue9: "",//
    attributeValue10: "寸",//
    attributeValue11: "",// 
    attributeValue12: "",//
    attributeValue13: "",//
    attributeValue14: "",//
    attributeValue15: "",//
    attributeValue16: "",//
}
// 显示类型
const UnitAccountList = {
    1: () => { return DermisUnitAccount },
    2: () => { return ArtificialLeatherUnitAccount },
    3: () => { return HardwareUnitAccount },
    4: () => { return HeelSoleUnitAccount },
    5: () => { return ClothUnitAccount },
    6: () => { return BraidedCordUnitAccount },
    7: () => { return ShoesUnitAccount },
    8: () => { return BagsSuitcasesUnitAccount }
}
// 显示类型
const ParametertList = {
    1: () => { return DermisUnitParameter },
    2: () => { return ArtificialLeatherParameter },
    3: () => { return HardwareParameter },
    4: () => { return HeelSoleUnitParameter },
    5: () => { return ClothUnitParameter },
    6: () => { return BraidedCordParameter },
    7: () => { return ShoesParameter },
    8: () => { return BagsSuitcasesParameter }
}
const productAttributeList = {
    1: () => { return DermisUnitproductAttribute },
    2: () => { return ArtificialLeatherproductAttribute },
    3: () => { return HardwareproductAttribute },
    4: () => { return HeelSoleUnitproductAttribute },
    5: () => { return ClothUnitLeatherproductAttribute },
    6: () => { return BraidedCordLeatherproductAttribute },
    7: () => { return ShoesproductAttribute },
    8: () => { return BagsSuitcasesproductAttribute }
}
const productTypeList = {
    0: () => { return '全部类别' },
    1: () => { return '真皮' },
    2: () => { return '人造革' },
    3: () => { return '五金饰品' },
    4: () => { return '鞋跟鞋底' },
    5: () => { return '布料' },
    6: () => { return '编织索带' },
    7: () => { return '鞋' },
    8: () => { return '箱包' },
    999: () => { return '其它' }
}

// 学历 
const Degreetype = {
    1: () => { return '小学' },
    2: () => { return '初中' },
    3: () => { return '高中' },
    4: () => { return '中专' },
    5: () => { return '大专' },
    6: () => { return '本科' },
    7: () => { return '硕士' },
    8: () => { return '博士' }
}


const UnitAccount = {
    // 根据产品类型获取计价单位
    getAccount(type) {
        return UnitAccountList[type] ? UnitAccountList[type]() : ''
    },
    getParameter(type) {
        return ParametertList[type] ? ParametertList[type]() : ''
    },
    getProductAttribute(type) {
        return productAttributeList[type] ? productAttributeList[type]() : ''
    },
    getproductType(type) {
        return productTypeList[type] ? productTypeList[type]() : ''
    },
    // 获取学历文本
    getDegreetype(type) {
        return Degreetype[type] ? Degreetype[type]() : ''
    },
}

export {
    UnitAccount
}