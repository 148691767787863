<template>
    <div class="searchResult" :key="new Date().getTime()">
        <el-page-header v-if="queryType == 'image'" @back="$router.go(-1)" content="图片搜索结果"></el-page-header>
        <el-page-header v-else @back="backPrivious" content="商品搜索结果"></el-page-header>
        <!-- banner -->
        <div class="banner" v-if="queryType == 'image'">
            <img src="@/assets/bannerColorSearch.png" alt="">
        </div>
        <SearchBox :enterpriseId="enterpriseId" v-if="queryType == 'text'" @handleSearchBox="handleSearchBox" ></SearchBox>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right" v-if="queryType == 'image'">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>图片搜索</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right" v-else>
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>搜索结果</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="f-c-c preview" v-if="previewsUrl && queryType == 'image'">
            <div class="f-cl-c elimageBox">
                <el-image :src="previewsUrl" :preview-src-list="[previewsUrl]"></el-image>
                <div class="imgText">搜索图片预览</div>
            </div>
            <div class="btnBox f-c-c">
                <div class="leftBtn f-c-b">
                    <div class="leftText">选择分类</div>
                    <el-select v-model="select" placeholder="请选择" @change="changeProductType">
                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="rightBtn" @click="showImageUploadBox = true">
                    重新搜索
                </div>
            </div>
        </div>
        <div>
        </div>
        <!-- v-if="queryType == 'image' -->
        <div v-if="tipText && queryType == 'text'" class="tip">搜索到 {{ total }} 条{{ '与 “' + tipText + '”' }} 相关的商品</div>
        <div class="list" v-if="searchList.length">
            <div class="listtitle">商品列表</div>
            <div class="tip" v-if="queryType == 'image'">
                已搜索到与搜索图片相似的{{ total }}件商品
            </div>

            <div class="listBox f-w">
                <div class="item f-cl-c" v-for="(item, index) of searchList" :key="index"
                    @click="toDetailPage(item.productId)">
                    <img class="itemimg" :src="item.img1Url">
                    <div class="product_cornermark">{{ item.productTypeStr }}</div>
                    <span class="product_name">{{ item.productName }}</span>
                    <span class="product_price">{{ item.unitPrice ? item.valuationCurrency : '' }}{{
                        item.unitPrice
                        ?
                        item.unitPrice.toFixed(2) : '暂无报价'
                    }}</span>
                    <div class="f-c-b infoBox">
                        <span v-if="item.productNumber" class="product_number">商品编号:{{ item.productNumber }}</span>
                        <span :class="item.shortageFlag == 'Y' ? 'stateY' : 'stateN'">{{ item.shortageFlag == 'Y' ? '售罄' :
                            '在售' }}</span>
                    </div>
                    <div class="btnBox f-cl-c-c" v-if="queryType == 'image'" @click.stop="showData(item)">
                        <div class="picturecomp">
                            图片对比<i class="el-icon-arrow-right"></i>
                        </div>
                        <span class="product_similarity" v-if="item.score">（相似度: {{ (Math.round(item.score * 10000)) / 100 +
                            '%' }}）</span>

                    </div>
                </div>
            </div>
            <Pagination v-if="queryType == 'text'" :total="total" :pageNum.sync="currentPage" :limit.sync="size"
                :layout="layout" @pagination="fetchData" />
        </div>
        <el-empty v-else description="暂无相关商品！"></el-empty>
        <Dialog :title="'图片对比'" width="810px" :visible="dialogShow" @closeDialog="closeDialog" class="commonDialog f-cl-c">
            <div class="similarity" v-if="popshowdata.score">相似度: {{ (Math.round(popshowdata.score * 10000)) / 100
                +
                '%' }}</div>
            <div class="imgBox f-c-c">
                <div class="f-cl-c leftimg">
                    <el-image :src="previewsUrl" :preview-src-list="[previewsUrl]"></el-image>
                    <span>所选图片</span>
                </div>
                <div class="f-cl-c">
                    <el-image :src="popshowdata.img1Url" :preview-src-list="[popshowdata.img1Url]"></el-image>
                    <span>搜索到的商品图</span>
                </div>

            </div>
            <div class="textBox" @click="toDetailPage(popshowdata.productId), dialogShow = false">
                <div class="firstline f-c-b">
                    <div class="productName">{{ popshowdata.productName }}</div>
                    <div class="cornermark">{{ popshowdata.productTypeStr }}</div>
                </div>
                <div class="secondline">
                    <span :class="popshowdata.shortageFlag == 'Y' ? 'stateY' : 'stateN'">{{ popshowdata.shortageFlag == 'Y'
                        ? '售罄' :
                        '在售' }}</span>
                </div>
                <div class="thirdline f-c-b">
                    <span class="number">{{ popshowdata.productNumber }}</span>
                    <span class="price">{{ popshowdata.unitPrice ? popshowdata.valuationCurrency : '' }}{{
                        popshowdata.unitPrice
                        ?
                        popshowdata.unitPrice.toFixed(2) : '暂无报价'
                    }}</span>
                </div>
            </div>
        </Dialog>
        <!-- 图片搜索 -->
        <ImageUpload1 @closeUploadBox="closeUploadBox" v-show="showImageUploadBox" :imageUploadTrends="imageUploadTrends"
            :ImageSearchtype="enterpriseId ? 'EnterpriseSearch' : 'ImageSearch'" @getpreviewsUrl="getpreviewsUrl"
            @getUploadImageSearchList="getUploadImageSearchList" :key="new Date().getTime() + 1">
        </ImageUpload1>
        <!-- 登录 -->
        <DialogLogin :loginBoxVisible="loginBoxVisible" @CloseLoginBox="CloseLoginBox"></DialogLogin>
        <!-- 职业 -->
        <DialogStanding :jodBoxVisible="jodBoxVisible" @CloseJobBox="CloseJobBox"></DialogStanding>
    </div>
</template>
<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin'
import { UnitAccount } from "@/utils/attributeValue1Options"
import SearchBox from '@/components/SearchBox.vue';
export default {
    name: 'search',
    inject: ['reload'],
    mixins: [PaginationRetention],
    components: {
        ImageUpload1: () => import(/* webpackChunkName: "ImageUpload" */ '@/components/ImageUpload.vue'),
        SearchBox
    },
    data() {
        return {
            searchList: [],
            productName: '',//搜索的产品名称
            tipText: '',
            productType: '',
            queryType: '',//请求类型
            queryName: '',
            queryList: [],
            size: 10,
            previewsUrl: '',
            options: [],
            select: this.$getStorage('productType') ?? 0,
            dialogShow: false,
            popshowdata: {},
            // previewsuploadFileName: '',
            previewsformdataParma: {},
            showImageUploadBox: false,
            imageUploadTrends: {
                'position': 'absolute',
                'right': '500px',
                'top': '500px'
            },
            enterpriseId: '',
            loginBoxVisible: false,  // 登录弹窗
            jodBoxVisible: false,  // 职业弹窗

        }
    },
    created() {
        let InvestigateFlag = this.$getStorage('InvestigateFlag');
        this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
        this.findSearchProductTypeList();
        let params = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
        this.queryType = params.type ?? '';
        this.queryName = this.$getStorage('searchProductInput') ?? params.name;
        this.tipText = this.$getStorage('searchProductInput') ?? params.name;
        this.enterpriseId = params.enterpriseId ?? '';
        this.queryList = this.$getStorage('queryList');
        this.previewsUrl = this.$getStorage('previewsUrl');
        this.previewsformdataParma = this.$getStorage('previewsformdataParma');
        this.getDate();
        this.$setStorage('isreload', true)
    },
    methods: {
        getDate() {
            this.queryType == 'text' ? this.findSearchProductList(this.paging, this.pagingSize) : this.findImageProductList();
            this.previewsUrl = this.$getStorage('previewsUrl') ?? '';
        },
        // 获取输入搜索框中的产品列表
        findSearchProductList(page = this.page, size = this.size) {
            let that = this,
                productName = that.queryName,
                productType = that.select,
                data = {
                    productName,
                    page,
                    size,
                    productType
                };
            that.$http.findSearchProductList(data).then(res => {
                if (res.code == 200) {
                    let { records } = res.data;
                    records.forEach(item => {
                        item.productTypeStr = UnitAccount.getproductType(item.productType)
                    })
                    this.searchList = records;
                    this.total = res.data.total;
                    this.currentPage = res.data.current;
                }
            })
        },
        // 获取上传图片的产品列表
        findImageProductList() {
            let productIdList = this.queryList
            if (productIdList.length) {
                this.$http.aLiProductImageSearchList({ productIdList }).then(res => {
                    if (res.code == 200) {
                        let { records } = res.data;
                        records.forEach(item => {
                            item.productTypeStr = UnitAccount.getproductType(item.productType)
                        })
                        let productIdSimilarityList = this.$store.state.productIdSimilarityList,
                            searchList = records;
                        let newList = productIdSimilarityList.map(item => {
                            let hasSimilarityList = searchList.find(i => i.productId == item.productId)
                            if (hasSimilarityList) {
                                return {
                                    ...hasSimilarityList,
                                    ...item
                                }
                            }
                        })
                        this.searchList = this.$common.filterUndefined(newList);
                        this.total = this.searchList.length;
                    }
                })
            } else {
                this.searchList = [];
            }
        },
        // 获取产品类型
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {};
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName
                        }
                        this.options.push(odj)
                    });
                }
            })
        },
        changeProductType(value) {
            this.$setStorage('productType', value);
            this.$store.commit('productType', value);
            this.Similaritysearch();
        },
        toDetailPage(productId) {
            if (!this.$getStorage('token')) {
                this.loginBoxVisible = true
            } else {
                this.$router.push({
                    name: 'ProductDetail',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: productId,
                            isshowsupply: this.enterpriseId != 0 ? false : true,
                        }))
                    }
                })
            }
        },
        showData(data) {
            this.dialogShow = true;
            this.popshowdata = data;
        },
        // 关闭弹框
        closeDialog(data) {
            this.dialogShow = data;
        },
        Similaritysearch() {
            let that = this,
                previewsformdataParma = this.$getStorage('previewsformdataParma');
            const formdata = new FormData();
            formdata.append("file", that.$store.state.saveFileData);
            formdata.append("num", previewsformdataParma.num ?? 20);
            formdata.append("productType", that.$getStorage('productType') ?? 0);
            formdata.append("enterpriseId", that.enterpriseId);
            that.aLiProductImageSearch(formdata);

        },
        aLiProductImageSearch(formdata) {
            let that = this;
            that.$http.aLiProductImageSearch(formdata).then(res => {
                if (res.code == 200) {
                    let productIdList = [],
                        newArr = [];//创建空数组
                    for (let i = 0; i < res.data.length; i++) {  //遍历图一数组
                        const obj = res.data[i];  //创建一个新对象，将图一数组的每一个值赋给obj对象
                        newArr.push({//给新数组里添加内容
                            'productId': obj.productId,
                            'score': obj.score
                        })
                    }
                    this.$store.commit('keepProductIdSimilarityList', newArr);
                    res.data.forEach(item => {
                        productIdList.push(item.productId)
                    })
                    that.queryList = productIdList;
                    that.$setStorage('queryList', productIdList);
                    that.getDate();
                }
            })
        },
        // 关闭上传图片盒子
        closeUploadBox(value) {
            this.showImageUploadBox = value
        },
        // 获取
        getUploadImageSearchList(list) {
            this.queryType = 'image'
            this.queryList = list;
            this.$setStorage('queryList', list);
            this.getDate();
        },
        getpreviewsUrl(img) {
            this.previewsUrl = img;
        },
        // 关闭登录弹窗
        CloseLoginBox() {
            this.loginBoxVisible = false;
            if (this.$getStorage('token')) {
                let InvestigateFlag = this.$getStorage('InvestigateFlag');
                this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
            }
        },
        // 关闭职业弹窗
        CloseJobBox() {
            this.jodBoxVisible = false;
            this.reload();
        },
        // 
        handleSearchBox(value, select) {
            let that = this;
            that.queryName = value;
            that.select = select;
            that.getDate();
            that.tipText = value;
        }

    },
}

</script>

<style lang="less" scoped>
.searchResult {
    margin: auto;


    .el-pagination {
        margin: 50px;
        text-align: right;
    }

    .banner {
        height: 100px;
        width: 1280px;
        margin: 40px 0;

        img {
            width: 100%;
            height: 100%;
        }
    }


    .breadcrumb {
        padding-bottom: 30px;
        border-bottom: 1px solid #F8F8F8;

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .preview {
        .elimageBox {
            margin-right: 60px;

            .el-image {
                width: 448px;
                height: 220px;
                border-radius: 20px;
                margin-bottom: 20px;
            }

            .imgText {
                font-size: 18px;
                font-family: HarmonyOS Sans SC;
                font-weight: 400;
                color: #333333;
            }
        }

        .btnBox {
            .leftBtn {
                width: 180px;
                height: 30px;
                border-radius: 10px;
                border: 1px solid #EDEDEE;
                margin-right: 10px;
                padding: 10px;

                .leftText {
                    font-size: 17px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #333333;
                }

                /deep/.el-input__inner {
                    width: 100px;
                    padding: 0;
                    border: none;
                    font-size: 17px;
                    font-family: HarmonyOS Sans SC;
                    // text-align: right;
                    font-weight: bold;
                    color: #333333;
                    // border: 1px solid red;
                    padding-left: 5px;
                }
            }

            .rightBtn {
                width: 145px;
                height: 50px;
                border-radius: 10px;
                text-align: center;
                line-height: 50px;
                cursor: pointer;
                color: #2D73FA;
                background-color: #EFF4FA;

            }
        }
    }

    .tip {
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        color: #999999;
        margin-bottom: 30px;
    }

    .list {
        .listtitle {
            font-size: 24px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #000000;
            line-height: 50px;
        }

        .tip {
            font-size: 16px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #999999;
            margin-bottom: 30px;
        }

        .listBox {

            .item {
                background: #F6F8FB;
                border-radius: 10px;
                width: 248px;
                height: 355px;
                margin-bottom: 20px;
                margin-right: 8px;
                position: relative;
                cursor: pointer;

                // margin: 12px;
                .itemimg {
                    margin-top: 12px;
                    width: 224px;
                    height: 168px;
                    border-radius: 10px;

                }

                .product_cornermark {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    border-radius: 5px;
                    background: #ffffff;
                    text-align: center;
                    height: 14px;
                    line-height: 14px;
                    font-size: 14px;
                    color: #699EF2;
                    padding: 7px 10px;
                }


                .product_name {
                    width: 100%;
                    font-size: 18px;
                    line-height: 25px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 500;
                    color: #333333;
                    text-align: left;
                    margin-left: 25px;
                    margin-top: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .product_price {
                    width: 100%;
                    font-size: 14px;
                    font-family: Poppins;
                    font-weight: 500;
                    color: #FF4E36;
                    text-align: left;
                    margin-left: 25px;
                    margin-top: 10px;
                }

                .infoBox {
                    margin-top: 10px;
                    width: calc(100% - 25px);

                    .product_number {
                        font-size: 12px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        color: #666666;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .stateY {
                        font-size: 16px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 500;
                        color: #FF4E36;
                    }

                    .stateN {
                        font-size: 16px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 500;
                        color: #06BC95;
                    }
                }

                .btnBox {
                    margin-top: 20px;
                    width: 110px;
                    height: 60px;
                    background: #CFE7FA;
                    // opacity: 0.2;
                    border-radius: 10px;

                    .picturecomp {
                        width: 68px;
                        height: 18px;
                        border-radius: 5px;
                        font-size: 12px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        background: linear-gradient(90deg, #026EF4 0%, #38A8F8 100%);
                        text-align: center;
                        line-height: 18px;
                        cursor: pointer;
                    }

                    .product_similarity {
                        margin-top: 10px;
                        // border: 1px solid red;
                        font-size: 12px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        color: #699EF2;
                        // display: block;
                    }
                }

            }
        }
    }

    .commonDialog {
        /deep/ .el-dialog {
            border-radius: 20px;

            /deep/ .el-dialog__title {
                font-size: 36px !important;
                font-family: HarmonyOS Sans SC !important;
                font-weight: 500 !important;
                color: #000000 !important;
            }
        }

        /deep/.el-dialog__header {
            padding: 30px;
        }

        /deep/ .el-dialog__title {
            margin-top: 40px !important;
            font-size: 36px !important;
            font-family: HarmonyOS Sans SC !important;
            font-weight: 500 !important;
            color: #000000 !important;
        }

        .similarity {
            width: 100% !important;
            font-size: 18px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #666666;
            text-align: center;
        }

        .imgBox {
            margin: 30px 0;

            .el-image {
                width: 200px;
                height: 200px;
                border-radius: 10px;
            }

            span {
                font-size: 18px;
                font-family: HarmonyOS Sans SC;
                font-weight: 400;
                color: #666666;
                margin-top: 10px;
            }

            .leftimg {
                margin-right: 120px;
            }
        }

        .textBox {
            width: 240px;
            height: 74px;
            border: 1px solid #E2E2E2;
            margin: auto;
            border-radius: 20px;
            padding: 26px 30px;
            cursor: pointer;

            .firstline {
                .productName {
                    width: 50%;
                    font-size: 23px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #000000;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .cornermark {
                    // width: 50%;
                    border-radius: 5px;
                    background: #E6F1FE;
                    // opacity: 0.1;
                    text-align: center;
                    height: 14px;
                    line-height: 14px;
                    font-size: 14px;
                    color: #699EF2;
                    padding: 5px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .secondline {
                margin: 10px 0;

                .stateY {
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 500;
                    color: #FF4E36;
                }

                .stateN {
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 500;
                    color: #06BC95;
                }
            }

            .thirdline {
                .number {
                    width: 50%;
                    font-size: 17px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #999999;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .price {
                    width: 50%;
                    font-size: 20px;
                    font-family: Poppins;
                    font-weight: 500;
                    color: #FF4E36;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-align: right;
                }
            }
        }

    }

}
</style>